import React from 'react'
import { useWindowSize} from "api/commonConstants";
import { Container } from 'react-bootstrap';
// import CustomIframe from './CustomIframe';
function Tally({masterConfig , showExitTally , showResultTally , stateName}) {

  const size = useWindowSize();
    

    
  return (
  
        <>
          { showExitTally != false && masterConfig?.MaharashtraExittally && (
            <>
              {size.width < 768 ? (
                <div className="result-page">
                  <h2
                    style={{
                      fontSize: "27px",
                      color: "#D9368B",
                      fontWeight: "800",
                    }}
                  >
                    विधानसभा एग्ज़िट पोल
                  </h2>
                  <div>
                    <iframe
                      src={!stateName ?  `${masterConfig?.MaharashtraExittally_URL}`   :`${masterConfig?.MaharashtraExittally_URL}&state_name=${stateName}`}
                      width="100%"
                      height={
                        masterConfig?.Maharashtra_Exitpolltally_height_mobile
                      }
                    ></iframe>
                  </div>
                </div>
              ) : (
                <Container>
                <div className="result-page  ">
                  <h2
                    style={{
                      fontSize: "27px",
                      color: "#D9368B",
                      fontWeight: "800",
                    }}
                  >
                    विधानसभा एग्ज़िट पोल
                  </h2>
                  <div>
                    <iframe
                    src={!stateName ?  `${masterConfig?.MaharashtraExittally_URL}`   :`${masterConfig?.MaharashtraExittally_URL}&state_name=${stateName}`}

                      width="100%"
                      height={
                        masterConfig?.Maharashtra_Exitpolltally_height_web
                      }
                    ></iframe>
                  </div>
                </div>
                </Container>
              )}
            </>
          )}
          {showResultTally != false && masterConfig?.MaharashtraResulttally && (
            <>
              {size.width < 768 ? (
                <div className="result-page-big">
                  <h2
                    style={{
                      fontSize: "27px",
                      color: "#D9368B",
                      fontWeight: "800",
                    }}
                  >
                    विधानसभा रिजल्ट
                  </h2>
                  <div>
                    <iframe
                      src={!stateName ? `${masterConfig?.MaharashtraResulttally_URL}` :`${masterConfig?.MaharashtraResulttally_URL}&state_name=${stateName}`}
                      width="100%"
                      height={
                        masterConfig?.Maharashtra_Resulttally_height_mobile
                      }
                    ></iframe>
                  </div>
                </div>
              ) : (
                <Container >
                <div className="result-page-big">
                  <h2
                    style={{
                      fontSize: "27px",
                      color: "#D9368B",
                      fontWeight: "800",
                    }}
                  >
                    विधानसभा रिजल्ट
                  </h2>
                  <div>
                    <iframe
                      src={!stateName ? `${masterConfig?.MaharashtraResulttally_URL}` :`${masterConfig?.MaharashtraResulttally_URL}&state_name=${stateName}`}
                      width="100%"
                      height={(size.width != 768 && size.width <= 1000) ? masterConfig?.Maharashtra_Resulttally_height_tab_internal :  masterConfig?.Maharashtra_Resulttally_height_web}
                      // height={(size.width != 768 && size.width <= 1000 )? masterConfig.Maharashtra_Resulttally_height_tab_internal   :
                      //   masterConfig
                      //     .Maharashtra_Resulttally_height_web
                      // }
                      // height={(window.innerWidth != 768 && window.innerWidth <= 1000) ? masterConfig?.Maharashtra_Resulttally_height_tab_internal :  masterConfig?.Maharashtra_Resulttally_height_web}

                    ></iframe>
                    {/* {size?.width && <CustomIframe stateName = {stateName} masterConfig = {masterConfig} size = {size}/>} */}
                  </div>
                </div>
                </Container>
                
              )}
            </>
          )}
          {masterConfig?.BypollResulttally && (
            <>
              {size.width < 768 ? (
                <div className="result-page-big">
                  <h2
                    style={{
                      fontSize: "27px",
                      color: "#D9368B",
                      fontWeight: "800",
                    }}
                  >
                    उपचुनाव रिजल्ट
                  </h2>
                  <div>
                    <iframe
                      src={masterConfig?.BypollResulttally_URL}
                      width="100%"
                      height={masterConfig?.Bypoll_Resulttally_height_mobile}
                    ></iframe>
                  </div>
                </div>
              ) : (
                <div className="result-page-big">
                  <h2
                    style={{
                      fontSize: "27px",
                      color: "#D9368B",
                      fontWeight: "800",
                    }}
                  >
                    उपचुनाव रिजल्ट
                  </h2>
                  <div>
                    <iframe
                      src={masterConfig?.BypollResulttally_URL}
                      width="100%"
                      height={masterConfig?.Bypoll_Resulttally_height_web}
                    ></iframe>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}


export default Tally
